@import '../../../../colors.css';

/* Currently, the markup is the same as in the AgendaModal component.
 * Hence the CSS rules from there apply. */

.task-list__filter-input {
  box-sizing: border-box;
  width: 100%;
}

.task-list__input-container {
  flex-shrink: 0;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.task-list__filter-input--invalid {
  border: 2px solid var(--red);
}

@import '../../../../../../colors.css';

.table-part {
  border-collapse: collapse;
  margin-top: 3px;
  margin-bottom: 3px;
}

.table-part__cell {
  border: 1px solid var(--base01);

  white-space: pre;

  vertical-align: top;
}

.table-part__cell--selected {
  background-color: var(--green-soft);
}

.table-cell__edit-container {
  width: 100%;
}

.table-cell__insert-timestamp-button {
  color: var(--base0);

  font-family: Courier;

  display: flex;
  align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}

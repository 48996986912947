@import '../../colors.css';

.header-bar {
  background-color: var(--base2);
  height: 30px;
  padding: 10px;

  display: grid;
  grid-template-columns: minmax(2em, 3.5fr) minmax(1em, 3fr) minmax(1em, 3.5fr);
  grid-template-areas: 'back title actions';
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 1;
}

.header-bar--with-logo {
  grid-template-columns: 5fr 1fr 5fr;
}

.header-bar__logo-container {
  display: flex;
  align-items: center;
}

.header-bar__logo {
  width: 30px;
}

.header-bar__app-name {
  color: var(--base01);
  margin: 0;
  margin-left: 5px;
}

.header-bar__title {
  color: var(--base01);
  text-align: center;
  font-size: 20px;
  height: 100%;
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.header-bar__back-button {
  color: var(--base01);
  text-decoration: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  grid-area: back;
}

.header-bar__back-button__directory-path {
  margin-left: 0.25em;
}

.header-bar__actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  color: var(--base01);
  grid-area: actions;
}

.header-bar__actions__item {
  color: var(--base01);

  margin-left: 0.5em;
}

.header-bar__actions__item--disabled {
  opacity: 0.5;
}

@import '../../colors.css';

footer {
  flex-shrink: 0;
  padding: 1rem;
  background-color: var(--base2);
  margin-top: 20px;
  text-align: center;
}

footer a {
  color: var(--base00);
}

.landing-container-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.landing-container a {
  text-decoration: none;
}

.landing-button {
  text-align: center;

  font-size: 20px;
  margin-top: 20px;
  width: 200px;
}

.landing-app-name {
  font-size: 80px;
  font-weight: 400;
  color: var(--base01);

  margin-top: 0;
  margin-bottom: 20px;
}

.landing-tagline {
  font-size: 24px;
  font-weight: 300;

  margin-top: 0;
  margin-bottom: 0.5em;

  text-align: center;
}

.landing-description {
  margin-left: 20px;
  margin-right: 20px;
}

.landing-logo {
  height: 7em;
}

.doc-button {
  margin-top: 20px;
  background-color: var(--green);
}

.mailing-list-signup-container {
  margin-top: 40px;
  font-size: 20px;
  max-width: 280px;
  text-align: center;
}
